export const formaDePago: { code: string; description: string }[] = [
    { code: '01', description: 'Efectivo' },
    { code: '02', description: 'Cheque' },
    { code: '03', description: 'Transferencia electrónica' },
    { code: '04', description: 'Tarjeta de crédito' },
    { code: '05', description: 'Monedero electrónico' },
    { code: '06', description: 'Dinero electrónico' },
    { code: '08', description: 'Vales de despensa' },
    { code: '12', description: 'Dación en pago' },
    { code: '13', description: 'Pago por subrogación' },
    { code: '14', description: 'Pago por consignación' },
    { code: '15', description: 'Condonación' },
    { code: '17', description: 'Compensación' },
    { code: '23', description: 'Novación' },
    { code: '24', description: 'Confusión' },
    { code: '25', description: 'Remisión de deuda' },
    { code: '26', description: 'Prescripción o caducidad' },
    { code: '27', description: 'A satisfacción del acreedor' },
    { code: '28', description: 'Tarjeta de débito' },
    { code: '29', description: 'Tarjeta de servicios' },
    { code: '30', description: 'Aplicación de anticipos' },
    { code: '31', description: 'Intermediario pagos' },
    { code: '99', description: 'Por definir' },
    { code: 'o', description: 'Otra' },
] as const

export const formaDePagoCredit = formaDePago.filter(
    (f) =>
        f.code === '03' ||
        f.code === '15' ||
        f.code === '27' ||
        f.code === '30' ||
        f.code === '99'
)

export type FormaDePagoMapKey = (typeof formaDePago)[number]['code']
type FormaDePagoMapValue = (typeof formaDePago)[number]['description']

export const formaDePagoMap = formaDePago.reduce((map, obj) => {
    map[obj.code] = obj.description
    return map
}, {} as Record<FormaDePagoMapKey, FormaDePagoMapValue>)
